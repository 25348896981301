@font-face 
{
  font-family: 'Gilroy';
  src: url('../public/fonts/Gilroy-Regular.ttf') format('truetype'); 
}

@font-face 
{
  font-family: 'Gilroy-ExtraBold';
  src: url('../public/fonts/Gilroy-ExtraBold.ttf') format('truetype'); 
}

@font-face 
{
  font-family: 'Gilroy-Light';
  src: url('../public/fonts/Gilroy-Light.ttf') format('truetype'); 
}