
form 
{
  display: flex;
  flex-direction: column;
  width: 200px;
  gap: 5px;
}

body
{
  margin: 0px;
  padding: 0px;
  background-color: #fbfdff;
  overflow-x: hidden;
}

.gilroy-light-20-400
{
  font-family: 'Gilroy-Light';
  font-size: 20px;
  font-weight: 400;
  line-height: 36px;
  color: #133b51;
}

.gilroy-32-400
{
  font-family: 'Gilroy-ExtraBold';
  font-size: 32px;
  font-weight: 400;
  color: #133b51;
}

button
{
  cursor: pointer;
  outline: none;
}